<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-8   mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Add On-Off On Web</p>
          </div>
          <div class="col-4 text-right">
            <button type="button" class="btn btn-sm bt-main" @click="back()">
              <span v-html="$svg_icon.back"></span> Back
            </button>
          </div>
        </div>
        <div class="row m-0 p-0 align-items-end">
          <div
            class="col-12 col-lg-4 mb-3
        "
          >
            <div class="form-group m-0">
              <label for="exampleInputPassword1">SKU Code</label>
              <div class="input-group m-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="SKU code"
                  v-model="data.SkCode"
                />
                <div class="input-group-append">
                  <span class="input-group-text btn bt-main" @click="getsku()"
                    >ตรวจสอบ</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8 mb-3">
            <p class="font-600 m-0 p-0">{{ name }}</p>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-group">
              <label>StartDate</label>
              <input
                type="date"
                class="form-control"
                v-model="data.StartDate"
                :disabled="name == ''"
              />
            </div>
          </div>
          <!-- ------------------------ -->
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-group">
              <label>EndDate</label>

              <div class="input-group ">
                <input
                  type="date"
                  class="form-control"
                  v-model="data.EndDate"
                  :disabled="data.StartDate == ''"
                  :min="data.StartDate"
                />
                <div class="input-group-append">
                  <button
                    class="btn bt-main"
                    @click="set50y"
                    :disabled="data.StartDate == ''"
                  >
                    50 YEAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- ------------------------ -->
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-group">
              <label>Mode</label>
              <select
                class="form-control"
                v-model="data.Mode"
                :disabled="name == ''"
              >
                <option value="">Mode...</option>
                <option
                  v-for="(e, i) in option_Mode"
                  :key="i"
                  :value="e.name"
                  >{{ e.name }}</option
                >
              </select>
            </div>
          </div>
          <!-- ------------------------ -->
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-group">
              <label>ShowOnWeb</label>
              <select
                class="form-control"
                v-model="data.ShowOnWeb"
                :disabled="name == ''"
              >
                <option value="">ShowOnWeb...</option>
                <option
                  v-for="(e, i) in option_ShowOnWeb"
                  :key="i"
                  :value="e.name"
                  >{{ e.name }}</option
                >
              </select>
            </div>
          </div>
          <!-- ------------------------ -->
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-group">
              <label>Remark</label>
              <input
                type="text"
                class="form-control"
                v-model="data.Remark"
                :disabled="name == ''"
              />
            </div>
          </div>
          <!-- ------------------------ -->
          <div class="col-12 col-lg-4 mb-3">
            <div class="form-group">
              <label>User Request</label>
              <input
                type="text"
                class="form-control"
                v-model="data.user_request"
                :disabled="name == ''"
              />
            </div>
          </div>
          <!-- ------------------------ -->
          <div class="col-12 text-center mb-3">
            <button type="button" class="btn bt-main px-5" @click="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddOnOff",

  data() {
    return {
      itemSelected: [],
      name: "",
      data: {
        SkCode: "",
        StartDate: "",
        EndDate: "",
        Mode: "",
        ShowOnWeb: "PWB",
        Remark: "",
        user_request: "",
      },
      option_Mode: [{ name: "on" }, { name: "off" }],
      option_ShowOnWeb: [ { name: "PWB" }],
    };
  },

  mounted() {},
  methods: {
    async validate() {
      return new Promise(async (resolve, reject) => {
        let { SkCode, StartDate, EndDate, Mode, ShowOnWeb } = this.data;
        if (SkCode == "") {
          resolve("Please enter SkCode");
        }
        if (StartDate == "") {
          resolve("Please enter StartDate");
        }
        if (EndDate == "") {
          resolve("Please enter EndDate");
        }
        if (Mode == "") {
          resolve("Please enter Mode");
        }
        if (ShowOnWeb == "") {
          resolve("Please enter ShowOnWeb");
        }
        resolve("OK");
      });
    },
    async save() {
      try {
        let _validate = await this.validate();
        if (_validate !== "OK") {
          throw _validate;
        }

        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          const data = { jsnProductShowOnWeb: this.data };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/uploadPimProductShowOnWeb`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$router.push({ name: "On-Off-OnWeb" });
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async back() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.$router.push({ name: "On-Off-OnWeb" });
      }
    },
    set50y() {
      // data.EndDate;
      const date = new Date();
      date.setFullYear(date.getFullYear() + 50);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let to = `${year}-${String(month).length == 1 ? `0${month}` : month}-${
        String(day).length == 1 ? `0${day}` : day
      }`;

      this.data.EndDate = to;
    },
    async getsku() {
      try {
        var data = { SkCode: this.data.SkCode };
        this.name = "";
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `product/getSku`,
          data,
          1
        );

        if (getAPI.data.length == 0) {
          throw `Not found sku`;
        }
        this.name = getAPI.data[0].name;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
